<template>
  <v-container>
    <layout :back="true" />
    <v-row>
      <v-col v-if="userType == 'candidate'">
        <candidate-profile />
      </v-col>
      <v-col v-else-if="userType == 'pharmacy'">
        <pharmacy-profile />
      </v-col>
      <v-col v-else-if="userType == 'pharmacygroup'">
       <pharmacy-profile-group />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//layout
import Layout from "@/components/layouts/Profile.vue";
// Profiles components
import CandidateProfile from "@/components/profiles/CandidateProfile.vue";
import PharmacyProfile from "@/components/profiles/PharmacyProfile.vue";
import PharmacyProfileGroup from "@/components/profiles/PharmacyProfileGroup.vue";

// import PharmacyProfileGroup from "@/components/profiles/PharmacyProfileGroup.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    Layout,
    CandidateProfile,
    PharmacyProfile,
    PharmacyProfileGroup
  },
  // data() {
  //   return {
  //     type: "candidate",
  //   };
  // },
  computed: {
    ...mapGetters({ userType: "user/type" }),
  },
};
</script>