export default [
	{
		title: "profile.edit_group",
		icon: "mdi-account-group",
		router: "edit-group-profile",
		key: "edit"
	},
	{
		title: "profile.update_logo",
		icon: "mdi-account-circle-outline",
		router: "",
		key: "groupLogo"
	}
	// {
	//     title: "profile.update_video",
	//     icon: "mdi-play-circle",
	//     router: "",
	//     key: "video"
	// },
];
