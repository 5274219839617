<template>
  <v-container v-if="mounted">
    <change-modal
      v-if="changeFiles"
      :dialog="changeFiles"
      :type="modalType"
      @close="closeFiles"
    />
    <video-player
      v-if="pharmacy.video"
      @closeVideoPlayer="toggleVideoPlayer"
      :showVideoPlayer.sync="videoPlayer"
      :src="pharmacy.video"
    />
    <v-row class="pa-0 ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="ml-2">
          <v-list-item-avatar tile size="80" v-if="pharmacyGroup.logo">
            <v-img
              alt="user avatar"
              class="shrink"
              contain
              :src="pharmacyGroup.logo"
              transition="scale-transition"
          /></v-list-item-avatar>
          <v-list-item-avatar tile size="80" v-else>
            <v-img
              alt="user avatar"
              class="shrink"
              contain
              src="@/assets/apotalent/Isotipo white.svg"
              @click="handleEvent('groupLogo')"
              style="cursor: pointer"
              transition="scale-transition"
          /></v-list-item-avatar>
          <v-list-item-content>
            <v-col cols="10" class="pa-0 ma-0"
              ><v-list-item-title class="text-subtitle-1 primary--text">{{
                pharmacyGroup.name
              }}</v-list-item-title>
              <v-list-item-subtitle
                class="primary--text text-caption font-weight-light opacity"
                >{{
                  `${
                    pharmacyGroup.billing_address
                      ? pharmacyGroup.billing_address.formatted
                      : null
                  }`
                }}</v-list-item-subtitle
              >
            </v-col>
            <v-col cols="2" class="mb-6 pa-0 ma-0 text-end">
              <v-menu left bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-dots-vertical </v-icon>
                </template>
                <!-- Profile dropdown list -->
                <v-list class="rounded-lg">
                  <v-list-item @click="() => {}" class="justify-end">
                    <v-icon color="primary">mdi-close</v-icon>
                  </v-list-item>
                  <v-row
                    dense
                    v-for="(option, index) in profileOptions"
                    :key="index"
                  >
                    <v-list-item
                      :to="option.router"
                      @click="
                        option.router.length > 0 ? {} : handleEvent(option.key)
                      "
                    >
                      <v-list-item-title
                        class="font-weight-light secondary--text"
                      >
                        <v-icon color="primary">{{ option.icon }}</v-icon>
                        {{ $tc(option.title, 2) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-row>
                </v-list>
              </v-menu>
            </v-col>
            <div></div>
            <div class="">
              <v-btn
                class="pa-0 mx-1 mb-1"
                v-if="false"
                :color="!pharmacy.video ? 'error' : 'success'"
                @click="
                  !pharmacy.video ? handleEvent('video') : toggleVideoPlayer()
                "
              >
                <v-icon> mdi-play-circle </v-icon>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item></v-col
      ></v-row
    >
    <v-row class="mx-0 mt-4" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <span style="cursor: pointer" class="ml-4" @click="newPharmacy">
          + {{ $t("group.add_pharmacy") }}
        </span>
      </v-col>
    </v-row>
    <v-row class="ma-0 mt-7" justify-sm="center">
      <v-col class="pa-0 px-4" cols="12" sm="8" md="5">
        <v-tabs
          fixed-tabs
          class="v-slider-group px-0 mx-0"
          active-class="shadowTabs"
        >
          <v-tabs-slider color="primary--text"></v-tabs-slider>
          <v-tab
            v-for="item in computedItems"
            :key="item"
            @change="changeItemSelected(item)"
            class="text-caption ma-0 pa-0 mx-2"
          >
            {{ $t(item) }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="itemSelected == this.$i18n.t('tabs.group')"
    >
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="primary--text ml-2">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("group_fields.group_data") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="itemSelected == this.$i18n.t('tabs.group')"
    >
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-0">
        <v-divider class="mx-2"></v-divider>
      </v-col>
    </v-row>

    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="itemSelected == this.$i18n.t('tabs.group')"
    >
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("group_fields.group_name") }} :</span
              >
              <span class="primary--text font opacity">
                {{ pharmacyGroup.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("group_fields.group_representive") }}:
              </span>
              <span class="primary--text font opacity">
                {{ pharmacyGroup.representative }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("group_fields.group_telephone") }}:
              </span>
              <span class="primary--text font opacity">
                {{ pharmacyGroup.phone_number }}
              </span>
            </v-list-item-subtitle>
            <!-- <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("group_fields.group_email") }}:
              </span>
              <span class="primary--text font opacity">
                {{ pharmacyGroup.email }}
              </span>
            </v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="itemSelected == this.$i18n.t('tabs.group')"
    >
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="primary--text ml-2">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("field.billing_data") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="itemSelected == this.$i18n.t('tabs.group')"
    >
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-0">
        <v-divider class="mx-2"></v-divider>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="itemSelected == this.$i18n.t('tabs.group')"
    >
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.fiscal_name") }} :</span
              >
              <span class="primary--text font opacity">
                {{ pharmacyGroup.fiscal_name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.cif") }} :</span
              >
              <span class="primary--text font opacity">
                {{ pharmacyGroup.cif }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.address") }} :</span
              >
              <span class="primary--text font opacity">
                {{
                  pharmacyGroup.billing_address
                    ? pharmacyGroup.billing_address.formatted
                    : null
                }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>

    <v-row
      class="ma-0 pa-0"
      justify-sm="center"
      v-if="itemSelected == this.$i18n.t('tabs.pharmacies')"
    >
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-6 px-5 mb-0">
        <v-select
          :items="pharmacies"
          v-model="pharmacyId"
          :label="$tc('pharmacy.title', pharmacies.length)"
          item-text="commercial_name"
          :rules="required"
          item-value="id"
          outlined
          class="pa-0 ma-0"
        ></v-select>
      </v-col>
    </v-row>
    <div
      v-if="childId != null && itemSelected == this.$i18n.t('tabs.pharmacies')"
      class="ma-0 pa-0"
    >
      <pharmacy-profile />
    </div>
  </v-container>
</template>
<script>
//import { mapGetters, mapActions } from "vuex";
//import Layout from "@/components/layouts/Profile.vue";
import ChangeModal from "@/components/modals/ChangeModal.vue";
import PharmacyProfile from "@/components/profiles/PharmacyProfile.vue";
import VideoPlayer from "@/components/modals/VideoPlayer.vue";
import { mapActions, mapGetters } from "vuex";
import profileOptions from "@/components/profiles/PharmacyProfileGroupOptions.js";
export default {
  components: {
    //Layout,
    ChangeModal,
    VideoPlayer,
    PharmacyProfile,
  },
  async created() {
    this.enableLoading();
    await this.getPharmacies();
    this.mounted = true;
    this.disableLoading();
    //this.matchPharmacyGroup();
  },
  async mounted() {
    await this.getPharmacyGroup(this.groupId);
  },
  data() {
    return {
      profileOptions: profileOptions,
      option: null,
      mounted: false,
      items: [this.$i18n.t("tabs.group"), this.$i18n.t("tabs.pharmacies")],
      itemSelected: this.$i18n.t("tabs.group"),
      pharmacyId: this.childId,
      pharmacySelected: {},
      modalType: null,
      changeFiles: false,
      videoPlayer: false,
      required: [(v) => !!v || this.$i18n.t("validation.required")],
    };
  },
  computed: {
    ...mapGetters({ pharmacy: "pharmacy/pharmacy" }),
    ...mapGetters({ pharmacyGroup: "pharmacy/pharmacyGroup" }),
    ...mapGetters({ pharmacies: "pharmacy/pharmacies" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ groupId: "user/groupId" }),

    computedItems() {
      return this.items;
    },
  },
  watch: {
    async pharmacyId() {
      await this.updateChildId(this.pharmacyId);
      await this.getPharmacyById(this.childId);
      //this.filterPharamcies();
    },
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ updateChildId: "user/updateChildId" }),
    ...mapActions({ getPharmacyGroup: "pharmacy/getPharmacyGroup" }),
    ...mapActions({ getPharmacies: "pharmacy/getPharmacies" }),
    ...mapActions({ getPharmacyById: "pharmacy/getPharmacyById" }),
    ...mapActions({ getPharmacyById: "pharmacy/getPharmacyById" }),
    ...mapActions({ updatePharmacyGroup: "pharmacy/updatePharmacyGroup" }),
    changeItemSelected(item) {
      this.itemSelected = item;
    },
    filterPharamcies() {
      this.pharmacySelected = this.pharmacies[1];
    },
    handleEvent(opt) {
      this.modalType = opt;
      this.changeFiles = true;
    },
    closeFiles() {
      this.changeFiles = !this.changeFiles;
    },
    toggleVideoPlayer() {
      this.videoPlayer = !this.videoPlayer;
    },
    newPharmacy() {
      this.$router.push({ name: "new-pharmacy" });
    },
  },
};
</script>
<style scoped>
.font {
  font-size: 13px;
}
.opacity {
  opacity: 0.6;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}
.shadowTabs {
  background-color: rgba(0, 0, 0, 0.12);
  font-weight: 700;
}
.v-slider-group {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
</style>
